.content-container {
    position: relative;
    z-index: 1;
    padding: 20px;
}




/* home.css */

  
  .content-container {
    width: 90%;
    margin: 0 auto;
  }
  
  .home-page {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .main-heading {
    color: #2f4858;
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .sub-heading {
    color: black;
    font-size: 1.3rem;
    margin-bottom: 20px;
    font-weight: 400;
  }
  
  .description {
    color: black;
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .login-signup {
    text-decoration: none;
  }
  
  .features-section {
    margin-top: 40px;
  }
  
  .features-section h2 {
    color: #b27e05;
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
  
  .features-list {
    list-style-type: none;
    padding-left: 0;
    color: black;
    font-size: 1.1rem;
  }
  
  .features-list li {
    margin-bottom: 10px;
  }
  
  .cta-section {
    margin-top: 40px;
  }
  
  .cta-section h2 {
    color: black;
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
  
  .cta-section p {
    color: black;
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .signup-link {
    text-decoration: none;
  }

  .home-image {
    width: 100%;
    height: auto;
    display: block;
    margin-top: 20px;
    border-radius: 15px;
  }
  
  /* Adjust button styles as needed */
  