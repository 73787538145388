.app-footer{
    background-color: #DFE0DF !important;
    text-align: center;
    padding: 20px 0;
    margin-top: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.footer-text{
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #7F7F7F;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: lato, sans-serif !important;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

.app-footer.visible {
    opacity: 1;
    z-index: 1000;
    visibility: visible;
  }

.app-footer.hidden {
    display: none;
  }

  .cubeish-url{
    color: #2F4858;
    font-weight: 700;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  @media only screen and (max-width: 600px) {
    .app-footer{
        padding: 10px 0;
    }
  }