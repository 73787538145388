.instructions{
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    text-decoration: none;
    font-family: lato, sans-serif !important;
    margin-bottom: 1em;
    margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;
}
.back-books-btn a{
    text-decoration: none !important;
    color: #000000 !important;
}
.search-input {
    width: 300px; /* Adjust the width as needed */
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    margin-right: 20px;
  }
  
  /* Optional: Add some styles for the placeholder text */
  .search-input::placeholder {
    color: #999;
  }
  .book-actions-codes{
    width: 50%;
    position: absolute;
    right: 15px;
    top: 115px;
  }
