.admin-table-container {
    width: 80%;
    margin: 20px auto;
}

.admin-table {
    width: 100%;
}

.admin-page {
    text-align: center;
    padding: 100px;
}

.header {
    color: #333;
    margin-top: 20px;
}

.edit-user-name,
.edit-user-email {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

.table-header-row {
    background-color: #f0f0f0;
}

.table-cell {
    padding: 12px;
    text-align: left;
}

.table-row:hover {
    background-color: #f9f9f9;
}

.edit-button,
.delete-button {
    margin-right: 5px;
    padding: 8px 12px;
}


/* signup.css */

/* Styles for the signup container */
.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-color: #f0f0f0; /* Background color for the entire page */
  }
  
  .logo-container {
    margin-bottom: 20px; /* Adjust margin as needed */
  }
  .signup-logo {
    width: 100px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
  }
  
  
  /* Styles for the signup form */
  .signup-form {
    background-color: #ffffff; /* Background color for the form */
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 350px;
    max-width: 90%;
    text-align: center;
  }
  
  /* Header styles */
  h1 {
    text-align: left;
    margin-bottom: 20px;
    color: #333333; /* Title color */
  }
  
  /* Input field styles */
  .signup-field {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .password-message {
    font-size: 14px;
    color: #888; /* Adjust the color as needed */
    margin-top: 5px;
  }
  
  /* Password visibility button styles */
  .password-visibilty-button {
    width: 30px;
    padding: 6px;
    margin: 10px 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  /* Password visibility icon styles */
  .password-visibilty-button > svg {
    width: 20px;
    height: 20px;
    color: #555;
  }
  
  /* Error message styles */
  .password-error,
  .password-mismatch-error {
    color: #ff0000; /* Error message color */
    margin-top: 5px;
    font-size: 14px;
  }
  
  /* Button styles */
  .submit-form-button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    background-color: #B08E32; /* Button background color */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-form-button:hover {
    background-color: #B08E32; /* Button hover background color */
  }
  
  .message {
    font-size: 14px;
    margin-top: 20px;
  }
  
  .message a {
    color: #B08E32; /* Link color */
    text-decoration: none;
    font-weight: bold;
  }
  
  .message a:hover {
    text-decoration: underline;
  }

  .create-user-button{
    background-color: #2F4858 !important;
    color: white !important;
  }
  
