.app-header-box {
  background-color: #2F4858 !important;
  top: 0 !important;
  left: 0 !important;
  position: fixed !important;
  width: 60px !important;
  height: 100vh !important;
}
.login-signup{
  color: #fff;
  text-decoration: none;
}
.login-signup-mobile{
  color: black;
  text-decoration: none;
}
.login-box{
  display: flex;
  justify-content: end;
}
.css-1lvtzne{
  justify-content: end;
}
.avatar-box{
  position: relative;
  right: 20px;
}
.myLibraryLink {
  text-decoration: none;
  color: #2F4858;
  background-color: #BDA05C;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  margin: 10px 10px 10px 10px;
  transition: background-color 0.3s ease;
  width: fit-content;
}

.myLibraryLink:hover {
  background-color: #8b6f24;
}


.link-dropdown {
  text-decoration: none;
  color: white;
  display: inline-block;
  text-align: center;
  transition: background-color 0.3s ease;
  padding: 10px; /* Adjust padding as needed */
  margin: 5px; /* Adjust margin as needed */
  cursor: pointer;

}

.link-dropdown svg {
  width: 24px; /* Adjust the icon size as needed */
  height: 24px;
  margin-bottom: -5px; /* Adjust vertical alignment of the icon */
}

