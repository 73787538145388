.contact-details{
    margin: 0 auto;
    width: 80%;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
}

.contact-details img{
    width: 500px;
    height: 500px;
    margin: 0 auto;
    display: block;
}